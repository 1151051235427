import loadable from "@loadable/component";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { useMemo } from "react";

import { getDateFormat, getTopic, LANGUAGES } from "@ihr-radioedit/inferno-core";
import { ContentFeedItemProps, getContentEyebrow } from "../../lib/content";
import { getEyebrow } from "../../services/Sites.utils";
import { Remote } from "../../components/remote/Remote.component";

const ContentTile = loadable(() => import("../../components/tiles/ContentTile.component"));

export const ContentFeedItem = inject("store")(
  observer(({ item, index, store, showEyebrow, showDateStamp, datetime, layout }: ContentFeedItemProps) => {
    if (!store) {
      return null;
    }

    const { site, microsite, getSdkOpts } = store;
    const locale = site.sections.general?.locale?.taxo?.name ?? LANGUAGES.English;
    const topic = getEyebrow(item.subscription);
    const eyebrow = useMemo(() => {
      if (item.payload.is_sponsored) {
        return { topic_name: i18n.t("sponsored_content") };
      } else if (item.payload.feed_vendor && item.payload.feed_partner_content) {
        return { topic_name: i18n.t("partner_content") };
      }
      return null;
    }, [item.payload.feed_vendor, item.payload.is_sponsored, item.payload.feed_partner_content]);

    if (!eyebrow && showEyebrow) {
      return (
        <Remote loader={() => getTopic(topic, locale, getSdkOpts())} cacheKey={`item-eyebrow-${item.ref_id}`}>
          {({ data }) => {
            const { itemUrl, eyebrowUrl, topicName } = getContentEyebrow(
              site,
              microsite ?? undefined,
              item,
              {
                topic,
                topic_name: data?.displayName ?? data?.name ?? "",
              },
              true,
            );

            return (
              <ContentTile
                title={item.summary.title}
                url={itemUrl}
                slug={`item_${index}`}
                action="item_selected"
                thumb={item.summary.image ?? ""}
                eyebrow={{ topic_name: topicName, url: eyebrowUrl }}
                sponsored={item.payload.is_sponsored}
                datetime={datetime}
                dateFormat={getDateFormat(site)}
                layout={layout}
                className="feed-content-item"
                showDateStamp={showDateStamp}
              />
            );
          }}
        </Remote>
      );
    }

    const { itemUrl: url } = getContentEyebrow(site, microsite ?? undefined, item, null, false);

    return (
      <ContentTile
        title={item.summary.title}
        url={url}
        slug={`item_${index}`}
        action="item_selected"
        thumb={item.summary.image ?? ""}
        eyebrow={showEyebrow ? eyebrow : null}
        sponsored={item.payload.is_sponsored}
        datetime={datetime}
        dateFormat={getDateFormat(site)}
        layout={layout}
        className="feed-content-item"
        showDateStamp={showDateStamp}
      />
    );
  }),
);

export default ContentFeedItem;
